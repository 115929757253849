export default {
  namespaced: true,
  state     : {
    user   : [],
    manager: false,
    top_secret_token:null,
    email_temp:null,
  },

  mutations: {
    changeUser(state, value) {
      state.user = value;
    },
    changeManager(state, value) {
      state.manager = value;
    },
    changeToken(state, value) {
      state.top_secret_token = value;
    },
    changeMail(state, value) {
      state.email_temp = value;
    },
  },
  getters  : {
    getUser   : state => state.user,
    getManager: state => state.manager,
    getToken: state => state.top_secret_token,
    getMail: state => state.email_temp,
  },

};
