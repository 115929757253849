<template>
  <div>
    <div style="height:200px;display:block;position: relative;background-color: rgb(50,50,50);box-shadow: 0 3px 3px rgba(100,100,100,.6)">

      <div style="position:absolute;overflow: hidden;width:100vw;height:100%;opacity: .7">
        <v-img v-if="!isMobile" alt="Karel de Stoute Main banner" position="center bottom" height="350" width="auto" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/banners/main1.webp" class="mainBanner" />
        <v-img v-if="isMobile" alt="Karel de Stoute Main banner" height="350" width="auto" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/banners/main1_mob.webp" class="mainBannerMobile" />
      </div>

      <div style="position: absolute;width:100%" :style="isMobile ? 'padding-top:80px' : 'padding-top:30px'" class="text-center">
        <p style="vertical-align: middle" class="white--text small">Het gezelligste verblijf van Nijmegen</p>
        <v-img class="" alt="divider image" height="20" contain src="@/assets/images/shapes/line-divider-orange.webp" />
        <v-img class="mt-n1" v-if="isMobile" alt="Scouting Karel de Stoute titel" contain width="99%" max-width="400" style="margin-top:-10px;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/titel1.webp" />
        <v-img class="mt-n6" v-if="!isMobile" alt="Scouting Karel de Stoute titel" contain width="800" height="150" style="margin-top:-30px;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/titel1.webp" />
      </div>
    </div>
       <!-- <h2 style="text-align: center;color:white;background-color: darkorange; margin:10px;">Er is een plekje vrijgekomen voor de 50km. Heeft u interesse? Neem dan contact met ons op.</h2>
-->
    <v-row :class="snackbar ? 'mt-6' : 'mt-3'">
      <v-col class="text-center" cols="12" md="6" lg="6" xl="6">
        <v-img alt="site intro" :height="isMobile ? 500 : 650" width="90%" style="max-width:90vw;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/mainpage/groepsfoto.webp" class="mt-2 mb-4" />
      <!--  <v-img alt="site intro" v-if="!isMobile" height="650" width="750" style="max-width:90vw;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/mainpage/groepsfoto.webp" class="mt-2 mb-4" />
-->
        <iframe style="margin-left:50%;transform: translate(-50%,0)" v-if="isActiveInfo" width="90%" :height="isMobile ? 200 : 400"
                src="https://www.youtube.com/embed/Wu_rCuelVu4" allowfullscreen>
        </iframe>
      </v-col>
      <div style="opacity: 0.001;position: absolute;top:100.1vh;height:1px;color:#ebeef5" v-observe-visibility="infoVisible">karel de stoute</div>

      <transition name="fade">
        <v-col class="mt-4 mb-4" xs="12" sm="12" md="6" lg="6">
<!--          <b style="padding: 5px;background-color:darkblue;color:white;font-size: 26px" >telefoonnummers busjes : 06-29293698 / 06-29293149</b>-->
          <tekst-blok v-if="!isMobile" :header="info.title" :html="info.text" :icon="info.icon" />
          <tekst-blok v-if="!isMobile" :header="kds.title" :html="kds.text" :icon="kds.icon" custom-icon />
          <tekst-blok v-if="!isMobile" :header="anders.title" :html="anders.text" :icon="anders.icon" />
          <tekst-blok v-if="!isMobile" :header="locatie.title" :html="locatie.text" :icon="locatie.icon" />

          <tekst-blok v-if="isActiveInfo && isMobile" :header="info.title" :html="info.text" :icon="info.icon" />
          <tekst-blok v-if="isActiveInfo && isMobile" :header="kds.title" :html="kds.text" :icon="kds.icon" custom-icon />
          <tekst-blok v-if="isActiveInfo && isMobile" :header="anders.title" :html="anders.text" :icon="anders.icon" />
          <tekst-blok v-if="isActiveInfo && isMobile" :header="locatie.title" :html="locatie.text" :icon="locatie.icon" />

          <div v-if="isActiveInfo && isMobile" style="opacity: 0.001!important;height:1px;color:#dedad5" v-observe-visibility="linksVisible">karel de stoute</div>

        </v-col>
      </transition>
    </v-row>

    <transition v-if="isActiveInfo && loaded" name="fade">
      <div v-if="isActiveInfo && loaded" style="background-color: #dedad5">
        <!--    <page-links folder="links" :items="pageLinks" v-if="isActiveInfo && loaded" :show_title="true" />-->
      </div>
    </transition>
  </div>
</template>

<script>
import TekstBlok from '@/commons/components/TekstBlok';
import axios from 'axios';

axios.defaults.headers.common['ApiKey'] = process.env.VUE_APP_API_KEY;

export default {

  name      : 'Main',
  components: {
    TekstBlok,

  },
  data() {
    return {
      snackbar     : true,
      guestsTotal  : false,
      info         : {
        title: 'Het 4daagseverblijf?',
        icon : 'help',
        text : 'Wij bieden een volledig verzorgd verblijf voor deelnemers van de 4daagse. Alles wordt voor u geregeld door vrijwilligers van Scouting Karel de Stoute. Alle opbrengsten komen ook ten goede van de Scouting.',
      },
      anders       : {
        title: 'Wat maakt ons anders?',
        icon : 'assignment_ind',
        text : 'Wat ons anders maakt dan anderen is dat het volledige verblijf geheel gerund\n' +
            'wordt door vrijwilligers van Scouting Karel de Stoute.\n' +
            'Mede door de scouting en de vaak terugkomende vaste gasten creëert dit een \n' +
            'huiselijke sfeer. Daarnaast proberen wij u volledig te ontzorgen, door alles voor u te regelen. Zo kunt u optimaal genieten van de 4daagse.',
      },
      locatie      : {
        title: 'Locatie',
        icon : 'explore',
        text : 'Ons verblijf ligt aan de rand van Nijmegen naast natuurgebied de \n' +
            '<a class="kdsLink" href="https://nl.wikipedia.org/wiki/Vogelzang_(Nijmegen)" target="_blank">Vogelzang</a>. Deze rustige ligging maakt het uitrusten na een dag lang \n' +
            'wandelen een waar welkom. \n' +
            'Als de beentjes wat zijn uitgerust is de Vogelzang een prachtig gebied \n' +
            'om lekker de beentjes uit te lopen om daarna weer fit te zijn voor de \n' +
            'volgende dag.'

        ,
      },
      kds          : {
        title: 'Scouting Karel de Stoute?',
        icon : 'kds-lion',
        text : 'Scouting Karel de Stoute is een vereniging die geheel in het teken staat van \n' +
            'leiden en begeleiden van kinderen tot volwassenen door vrijwilligers.\n' +
            'Onze groep bestaat inmiddels meer dan 50 jaar en heeft op het moment ongeveer 220 leden.\n' +
            'De "leiding" is een hechte groep vrijwilligers, die met zijn allen er voor zorgen dat u bij ons een goed 4daagseverblijf heeft.',
      },
      isActiveInfo : false,
      isActiveLinks: false,
      loaded       : false,
      pageLinks    : [
        {
          title: 'Leeftijden',
          text : 'Een overzicht van onze leeftijdsgroepen',
          img  : 'speltakken',
          url  : '/speltakken',
        },
        {
          title: 'Verhuur',
          text : 'Verhuurinformatie van onze locatie',
          img  : 'verhuur',
          url  : '/verhuur',
        },
        {
          title: 'Lid worden',
          text : 'Alle informatie voor nieuwe leden',
          img  : 'lid-worden',
          url  : '/lid_worden',
        },
        {
          title: 'Sponsors',
          text : 'Een overzicht van onze sponsors',
          img  : 'sponsorsen',
          url  : '/sponsors',
        },
        {
          title: 'Groepsagenda',
          text : 'Alle agenda\'s van onze groep op een rij',
          img  : 'agenda',
          url  : '/groeps-agenda',
        },
        {
          title: 'SponsorKliks',
          text : 'Direct naar onze sponsorKliks pagina',
          img  : 'sponsorkliks',
          url  : 'https://www.sponsorkliks.com/products/shops.php?club=11871',
        },

      ],

    };
  },

  methods: {
    infoVisible(isVisible, entry) {
      isVisible ? this.isActiveInfo = true : '';
    },
    linksVisible(isVisible, entry) {
      isVisible ? this.isActiveLinks = true : '';
    },
    getGuests() {
      axios.get(process.env.VUE_APP_API_URL + 'count').then((res) => {
        this.guestsTotal = res.data.guests;

      });
    },
    getColor(number){
      if(number > 0 && number < 4){
        return 'orange';
      }
      if(number <= 0 ){
        return 'red';
      }

      if(number >= 4 ){
        return 'green';
      }
    }

  },

  computed: {
    isMobile() {
      return window.innerWidth < 1020;
    },

    mainIntro() {
      return window.innerWidth < 1020 ? 'siteintromobile.webp' : 'siteintrolarge.webp';
    },
    thirtyPlaces() {
      if(this.guestsTotal) {
        let max   = 16;
        let index = this.guestsTotal.findIndex(val => {
          return val.distance === 30;
        });

        return max - this.guestsTotal[index].aantal;
      }
    },
   fortyPlaces() {
     if(this.guestsTotal) {
       let max   = 24;
       let index = this.guestsTotal.findIndex(val => {
         return val.distance === 40;
       });

       return max - this.guestsTotal[index].aantal;
     }
    },
    fiftyPlaces() {
      if(this.guestsTotal) {
        let max   = 16;
        let index = this.guestsTotal.findIndex(val => {
          return val.distance === 50;
        });

        return max - this.guestsTotal[index].aantal;
      }
    },

  },

  mounted() {
    this.getGuests();
    if (this.isMobile) {
      setTimeout(() => {
        this.loaded = true;
      }, 3000);
    }
    if (!this.isMobile) {
      this.loaded = true;
    }
  },
};
</script>


