import Vue from 'vue';
import Router from 'vue-router';
import {routes} from './routes/routes';
import store from '../store/store';

Vue.use(Router);

const router = new Router({
    mode  : 'history',
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.Auth)) {
console.log(store.getters['preferences/getUser']);
        /** check if user is logged in **/
        if (!store.getters['preferences/getUser'] || store.getters['preferences/getUser'].length === 0) {
            next({
                path: '/403',
            });
        }
        next();
    }
    next();
});

export default router;
