/**import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import nl from 'vuetify/lib/locale/nl';

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { nl },
        current: 'nl',
    },
    font         : {
        family: false,
    },
    theme: {
        disable: true
    },
    defaultAssets: false,
    treeShake    : true,
    rtl          : false,
    icons: false,
});*/

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts)
