<template>
    <div>
        <h3 class="ml-4 textHeader" v-html="header"></h3>

        <v-icon v-if="!avatar && !customIcon && (iconsVisible || !isMobile)" :color="color" class="iconHolder" size="60">{{ icon }}</v-icon>
    <div v-if="avatar" class="iconHolder"><v-img max-width="60" :lazy-src="require('@/assets/images/no-image.webp')" :src="icon"/></div>
        <v-icon v-if="!avatar && customIcon  && (iconsVisible || !isMobile)" :class="icon" class="customIcon iconHolder"></v-icon>

        <div v-if="!Array.isArray(html)" class="pt-6 mr-4 textHtml" v-html="html"></div>
        <div v-if="Array.isArray(html)" class="pt-6 mr-4 textHtml">
            <div v-if="Array.isArray(html)" v-for="item in html" v-html="getHtml(item)"></div>
        </div>

        <v-img v-if="(iconsVisible || !isMobile)" width="365" class="textDivider" :lazy-src="require('@/assets/images/no-image.webp')" :src="require('@/assets/images/shapes/line-divider-blue.webp')" />
    </div>

</template>

<script>
export default {
    name    : 'InfoBlok',
    data() {
        return {
            iconsVisible: false,
        };
    },
    props   : {
        header    : {
            type: String,
        },
        icon      : {
            type: String,
        },
        html      : {},
        color     : {
            type   : String,
            default: '#164080',
        },
        id        : {
            type    : String,
            required: false,
        },
        customIcon: {
            type    : Boolean,
            required: false,
        },
      avatar:{
          type:Boolean,
        required: false,
      }

    },
    created() {
        window.addEventListener('scroll',this.setIconsVisible );
    },
    mounted() {

    },
    methods : {
        getHtml(item) {
            if (item.includes('CHECKBOX')) {
                item = '<p><span style="font-size: inherit;color:darkgreen" class="material-icons mr-2">done_outline</span>' + item.substring(8) + '</p>';
            }
            return item;
        },
        setIconsVisible(){
            this.iconsVisible=true;
        }
    },
    watch   : {},
    computed: {
        isMobile() {
            return window.innerWidth < 1020;
        },
    },
};
</script>
