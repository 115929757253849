<template>
    <div>
       <v-app :style="isMobile ? 'overflow-x: hidden !important;' : 'overflow: hidden !important;'" style="background-color:#ebeef5">

        <kds-snackbar v-if="$root.snackbar"></kds-snackbar>

           <div v-if="!isMobile" style="height:80px">
               <the-header v-if="!isMobile && !['Barlijst'].includes($router.currentRoute.name)" ref="header" class="header-height" />
           </div>

      <the-mobile-header v-if="isMobile && !['Barlijst'].includes($router.currentRoute.name)" ref="mobileheader" class="header-height" />
        <contact-form v-if="$root.contactForm" :open="$root.contactForm" ref="contact" />
        <sign-on-form v-if="$root.signOnForm" :open="$root.signOnForm" ref="signOn" />
        <login-form v-if="$root.loginForm" :open="$root.loginForm" ref="login" />
        <transition name="fade">
            <router-view />
        </transition>

       <!-- <div v-if="isMobile && $router.currentRoute.name ==='home'" style="position: absolute;color:transparent;top:2500px" v-observe-visibility="footerVisibile">karel de stoute</div>
        <div v-if="!isMobile && $router.currentRoute.name ==='home'" style="position: absolute;color:transparent;top:1200px" v-observe-visibility="footerVisibile">karel de stoute</div>-->
        <TheFooter v-if="isActive || !['home','Barlijst'].includes($router.currentRoute.name)" />
    </v-app>
    </div>
</template>

<script>

import TheMobileHeader from '@/layouts/globals/MobileMenu';
import axios from "axios";

export default {
    name      : 'App',
    components: {
        TheMobileHeader,
        'TheHeader'  : () => import(/** webpackChunkName: "HeaderPC" */ '@/layouts/globals/Header'),
        'TheFooter'  : () => import(/** webpackChunkName: "HeaderMobile" */ '@/layouts/globals/Footer'),
        'ContactForm': () => import(/** webpackChunkName: "Contactform" */ '@/commons/components/ContactForm'),
        'SignOnForm': () => import(/** webpackChunkName: "Signon form" */ '@/commons/components/SignOnForm'),
        'LoginForm': () => import(/** webpackChunkName: "LoginForm" */ '@/commons/components/LoginForm'),
        'KdsSnackbar': () => import(/** webpackChunkName: "Snackbar" */ '@/commons/components/KdsSnackbar'),
    },
    data() {
        return {
            isActive: false,
            isLoaded: false,
          notificationsSupported: false,
        };
    },
  created(){

    if (this.$workbox) {
      this.askPermission();
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
  },
    mounted() {
      if (this.$router.currentRoute.name === 'home') {

        axios.post(process.env.VUE_APP_API_URL + 'banner' ).then(response => {
          if(response.data.text !== ''){
            this.$root.snackbarTitle =  response.data.text;
            this.$root.snackbarColor = response.data.color;
            this.$root.snackbar = true;
          }
        });
      }

             if (this.$router.currentRoute.name === 'home' && this.isMobile) {
                    setTimeout(() => {
                        this.loadIcons();
                        this.initGtm('UA-129661748-1');
                    }, 4200);
        } else {

            this.loadIcons();
          this.initGtm('UA-129661748-1');
          this.initGtm('AW-967199307');
        }



    },
    methods   : {
        footerVisibile(isVisible, entry) {
            this.trigger = isVisible;
            isVisible ? this.isActive = true : '';
        },
      askPermission() {
          Notification.requestPermission(result => {
            console.log('result from permission question', result);
            if (result !== 'granted') {

            } else {
              console.log('A notification will be send from the service worker => This only works in production');

            }
          })
         },

        initGtm(acct) {
            var gaJsHost = (('https:' === document.location.protocol) ? 'https://ssl.' : 'http://www.'),
                pageTracker,
                s;
            s            = document.createElement('script');
            s.src        = gaJsHost + 'google-analytics.com/ga.js';
            s.type       = 'text/javascript';
            s.onloadDone = false;

            if(_gat) {

              function init() {
                pageTracker = _gat._getTracker(acct);
                pageTracker._trackPageview();
              }

              s.onload = function() {
                s.onloadDone = true;
                init();
              };

              if (('loaded' === s.readyState || 'complete' === s.readyState) && !s.onloadDone) {
                s.onloadDone = true;
                init();
              }

              if (window.gtmDidInit) {
                init();
              }

              if (!window.gtmDidInit) {
                window.gtmDidInit = true;
                document.getElementsByTagName('head')[0].appendChild(s);
              }
            }

        },
        loadIcons() {
            this.loaded = true;
            var link    = document.createElement('link');
            link.rel    = 'stylesheet';
            link.type   = 'text/css';
            link.href   = 'https://fonts.googleapis.com/css?family=Material+Icons&display=block';
            document.head.appendChild(link);
        },
    },
    computed  : {
        isMobile() {

            return window.innerWidth < 1020;
        },

      isSnackbarActive() {
          return this.$root.snackbar;
        }
    },
    watch     : {
      isSnackbarActive(val){
       if(!val){
         this.$root.snackbarTitle=false;
         this.$root.snackbarColor=false;

       }
      },
      $route(to, from) {
        this.loadIcons();
        if (!window.location.href.split('#')[1]) {
          window.scrollTo({
            top     : 0,
            behavior: 'smooth',
          });
        }
        setTimeout(() => {
          this.initGtm('UA-129661748-1');
          this.initGtm('AW-967199307');
        }, 2000);
      },

    }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .75s;
}

.fade-enter-active {
    transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
    opacity: 0
}
</style>
