<template>
    <div>
        <img @click="navigate('/')" alt="Scouting Karel de Stoute Mobiel logo" class="mt-1 pl-1" height="70" width="120" style="position: fixed;z-index:1041;float:left"
             src="@/assets/images/4daagselogo_mobile.webp" />
        <div style="height:58px;width:100%;z-index:1040;background-color:white;position: fixed" class="hidden-md-and-up">



          <div style="margin-top:.5em;width:100%">
            <div style="margin-left:55%;transform:translate(-50%,0);width:35%;display: flex;justify-content: space-evenly" class="">
              <button aria-label="contact-button" :ripple="false" >
                <v-icon class="customIcon mail-header icon-gray" @click="$root.contactForm = true"></v-icon>
              </button>

              <a aria-label="whatsapp-button" :ripple="false" target="_blank" href="https://wa.me/31615566787" title="Stel uw vraag via whatsapp" >
                <v-icon class="customIcon wa icon-gray"></v-icon>
              </a>
            </div>
          </div>

            <button style="position: absolute;right:1em;top:1em" :ripple="false" aria-label="menu-button" id="menu-button" @click="dialog=!dialog">
                <v-icon v-if="dialog" class="customIcon close"/>
                <v-icon v-if="!dialog" class="customIcon menu"/>
            </button>
        </div>

        <menu-dialog v-if="dialog" :active="dialog" @close-dialog="dialog=false"/>

    </div>
  </template>

<script>
export default {
    name    : 'MobileMenu',
    components:{
        'MenuDialog': () => import(/** webpackChunkName: "MenuDialog" */ '@/layouts/globals/MenuDialog'),
    },
    data() {
        return {
            selectedItem: null,
            dialog:false,

        };
    },
    methods:{
        navigate(item){
            this.$router.push(item).catch(()=>{});
        }
    }
   };
</script>
