import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

/** read .env file */
import axios from 'axios';
/** --- Import the needed css files here.. so they are not needed in the header (which is bad for performance) */

require('@/assets/css/custom.css');
require('@/assets/css/main.scss');
//require('default-passive-events');

import Vue from 'vue';

import App from './App.vue';
import router from './router';

import vuetify from './plugins/vuetify';
import store from '@/store/store';

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed,{
default: true
})

import {ObserveVisibility} from 'vue-observe-visibility';
Vue.directive('observe-visibility', ObserveVisibility);

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({

  broadcaster      : 'pusher',
  forceTLS         : false,
  key              : process.env.VUE_APP_PUSHER_APP_KEY,
  cluster          : 'eu',
  wsHost           : 'api.bbtoolz.nl',
  wsPort           : null,
  enabledTransports: ['ws','wss'],
  disableStats     : false,
  encrypted        :false,
});
/*


import firebaseMessaging from './firebase'

Vue.prototype.$messaging = firebaseMessaging*/

new Vue({
  data  : {
    contactForm : false,
    loginForm   : false,
    signOnForm  : false,
    snackbar    : false,
    cacheKey        : 1,
    snackbarText: null,
    openLunch:null,
    openChecklist:null,
    openChecklistTab:null,
    openData:null,
    snackbarTitle: null,
    snackbarColor: null,
    shoppingList: [],
  },
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');




