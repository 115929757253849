import Home from '../../layouts/Main';

const MainTemplate   = () => import( '../../layouts/MainTemplate');
const FourOFour      = () => import( '../../layouts/error-pages/404');
const AccessDenied   = () => import( '../../layouts/error-pages/403');
const ManagementView = () => import( '../../layouts/ManagementView');
const Reviews        = () => import( '../../layouts/Reviews');
const BarComponent        = () => import( '../../layouts/BarComponent');

export const routes = [
  {
    path     : '/',
    name     : 'home',
    component: Home,
    meta     : {Auth: false},
  },
  {
    path     : '/beheer/:tab',
    name     : 'Beheer',
    component: ManagementView,
    meta     : {Auth: true},
  },
  {
    path     : '/beheer',
    name     : 'Beheer',
    component: ManagementView,
    meta     : {Auth: true},
  },
  {
    path     : '/barlijst',
    name     : 'Barlijst',
    component: BarComponent,
    meta     : {Auth: false},
  },
  {
    path     : '/reviews',
    name     : 'Reviews',
    component: Reviews,
    meta     : {Auth: false},
  },
  {
    // catch all route
    path     : '/403',
    component: AccessDenied,
    meta     : {Auth: false},
  },
  {
    path     : '/:page',
    name     : '4daagse info',
    component: MainTemplate,
    meta     : {Auth: false},
  },
   {
    // catch all route
    path     : '/*',
    component: FourOFour,
    meta     : {Auth: false},
  },
];
